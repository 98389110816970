import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class CacheFilterService {
	private filterValues: { [key: string]: FormControl } = {};

	constructor(private activatedRoute: ActivatedRoute) {}

	setFilterValue(filterValue: any) {
		const pageKey = this.getPageKey();
		if (this.filterValues[pageKey].value) {
			this.filterValues[pageKey].patchValue(filterValue);
		} else {
			this.filterValues[pageKey] = new FormControl(filterValue);
		}
	}

	getFilterValue(): FormControl {
		const pageKey = this.getPageKey();
		return this.filterValues[pageKey] || (this.filterValues[pageKey] = new FormControl(undefined));
	}

	clearFilterValue() {
		const pageKey = this.getPageKey();
		delete this.filterValues[pageKey];
	}

	clearAllFilterValues() {
		this.filterValues = {};
	}

	private getPageKey(): string {
		const segments: string[] = [];
		let currentRoute: ActivatedRoute | null = this.activatedRoute;
		while (currentRoute) {
			if (currentRoute.snapshot.url.length > 0) {
				segments.unshift(currentRoute.snapshot.url.map((segment) => segment.path).join('_'));
			}
			if (currentRoute.snapshot.params && Object.keys(currentRoute.snapshot.params).length > 0) {
				segments.push(...Object.values(currentRoute.snapshot.params));
			}
			currentRoute = currentRoute.firstChild;
		}
		return segments.join('_');
	}
}
